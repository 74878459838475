import { Link } from "gatsby"
import React, { Component } from "react"

export default class Navbar extends Component {
  render() {
    return (
      <div>
        <Link to={`/`}> Home </Link> {" | "}
        {this.props.items.map(element => (
          <>
            <Link to={`/${element.toLowerCase()}`}>{element} </Link> {" | "}
          </>
        ))}
        {/* <pre>{JSON.stringify(this.props, null, 2)}</pre> */}
      </div>
    )
  }
}
