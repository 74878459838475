import React from "react"
import Navbar from "../../Components/NavBar"

export default function Home() {
  return (
    <>
      <Navbar items={["Offers", "Prices", "Contact"]} />

      <img src="https://source.unsplash.com/random/400x200" alt="" />

      <div>Hello world!</div>

      <button class="primary-button">Click me</button>
    </>
  )
}
